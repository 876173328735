<template>
  <div class="seachBox">
    <el-input
      placeholder="搜索药品"
      prefix-icon="el-icon-search"
      @focus="focus"
      @blur="blur"
      v-model="seachInp"
    >
    </el-input>
    <span class="searchBtn" @click="searchFun()">搜索</span>

    <!-- 搜索商品弹窗 start-->
    <el-dialog
      class="searchDiolag"
      title="搜索结果"
      :visible.sync="searchDialogFlag"
      :close-on-click-modal="false"
    >
      <div class="DialogSearchBtnBox">
        <span class="searchNum">搜索到<strong>{{total}}</strong>个商品</span>
        <div class="seachBox">
          <el-input
            placeholder="搜索药品"
            prefix-icon="el-icon-search"
            @focus="focus"
            @blur="blur"
            v-model="seachInp"
          >
          </el-input>
          <span class="searchBtn" @click="DiologSearchFun()">搜索</span>
        </div>
      </div>
      <div class="ProductListBox">
          <div  class="NoProductList"  v-if="this.total==0">
              未找到搜索关键值商品，请重新输入搜索值搜索
          </div>
          <div class="HasProductList"  v-else>
            <div class="HasProductBox"  v-for="(item, index)  in searchProducts" :key="index" >
                 <div class="imgBox"   @click="jumpGoodsDetail(item)">
                    <img v-if="item.quantity<Number(item.minOrderNum)" class="soldOut" alt="补货中" src="@/assets/soldOut.png" />
                    <img :alt="item.name | defaultName" :src="item.pic+picSuffix | defaultImage" />
                </div>
                 <div  class="desBox">
                        <el-col :span="5">
                          <div class="desTit">
                            <span :title="item.name">{{ item.name }}</span>
                          </div>
                          <div class="desMsg">
                            <span v-if="item.specification">{{ item.specification }}</span>
                          </div>
                        </el-col>
                        <el-col class="SpecBox" :span="3">
                          {{ item.partSpecification }}
                        </el-col>
                        <el-col class="SpecBox" :span="5">
                          效期优于:{{ item.validDateFromat }}
                        </el-col>
                        <el-col class="SpecBox" :span="3">
                          库存:{{item.quantity}}{{item.unit}}
                        </el-col>
                        <el-col class="PriceBox" :span="4">
                         {{ item.price==="***"?"会员可见":item.price  | capitalize }}
                        </el-col>
                        <el-col class="PurchasePlanBtn"  :span="4">
                          <span @click="AddPurchasePlan(item)">加入采购计划</span>
                        </el-col>
                    </div>
            </div>
          </div>
        </div>
    </el-dialog>
    <!-- 搜索商品弹窗 end -->
  </div>
</template>

<script>
import { 
  SaveExportProduct,// 新增导入商品接口
} from "api/order.js";
import {
  SearchValProducts,
} from "api/product.js";
export default {
  name: "searchDiolag",
  data() {
    return {
       picSuffix:localStorage.getItem("productPic"),
      seachInp: "", //搜索关键字
      searchDialogFlag: false, //搜索框弹窗开关
      total:0,//搜索到数量
      searchProducts:'',//搜索到商品列表数据
    };
  },
  props: {
    // obj:{
    //     type:Object
    // }
  },
  methods: {
    // 商品详情
    jumpGoodsDetail(item){
       this.$router.push({ name: "ProductDetail", query: { id: item.id,isRecentExpiration:item.isRecentExpiration } });
    },
    AddPurchasePlan(item){
      console.log('aaa',item)
          let datas = {
            productId: item.id,//商品id
            goodNum:item.goodNum, //商品ERP编号
            isRecentExpiration:item.isRecentExpiration, //是否近销
            amount:item.price,// 商品价格
            goodName:item.name,// 商品名称
            quantity:item.minOrderNum,// 商品数量
            specification:item.specification,//规格
             manufacturerName:item.manufacturerName
          };
          SaveExportProduct(datas).then((res) => {
            console.log(res)
            if(res.data.code==200){
               this.$message({
                message: '加入商品成功',
                type: 'success'
              });
              this.searchDialogFlag=false
              this.$emit("DialogF")
            }else{
               this.$message({
                message: res.data.msg,
                type: 'warning'
              });
            }
          });
    },
    focus() {
      this.isFocus = true;
    },
    blur() {
      var self = this;
      this.searchBoxTimeout = setTimeout(function() {
        self.isFocus = false;
      }, 300);
    },
    DiologSearchFun(){
          // 显示loading
        this.$loading({ fullscreen: true ,background:'#ffffff45'})
        const parmesData = {
            //暂无
            keyword: this.seachInp,
            current: 1,
            size: 10
          };
        SearchValProducts(parmesData).then((res) => {
          const records = res.data.data.records;
          this.searchProducts = records;
           this.total = Number(res.data.data.total);
             // 关闭loading
          this.$loading().close();
        });
    },
    searchFun() {
      if (this.seachInp) {
        this.searchDialogFlag = true;
          // 显示loading
        this.$loading({ fullscreen: true ,background:'#ffffff45'})
        const parmesData = {
            //暂无
            keyword: this.seachInp,
            current: 1,
            size: 10
          };
        SearchValProducts(parmesData).then((res) => {
          const records = res.data.data.records;
          this.searchProducts = records;
           this.total = Number(res.data.data.total);
             // 关闭loading
          this.$loading().close();
        });
      } else {
         this.$message({
          message: '请输入搜索药品',
        });
      }
    },
    //   changeIndex(index){
    //     this.obj.currentIndex = index;
    //     this.$emit("clickIndex",this.obj.currentIndex)
    //   }
  },
  created() {},
};
</script>

<style lang="less" scoped>
 .DialogSearchBtnBox {
    padding:0px 0px 20px 0px;
    height: 44px;
    .searchNum{
      line-height: 40px;
    }
}
.seachBox {
  width: 450px;
  float: right;
  /deep/.el-input {
    width: 80%;
    float: left;
    border-radius: 0;
    .el-input__inner {
      width: 100%;
      border-radius: 0;
    }
  }
  .searchBtn {
    width: 20%;
    float: left;
    text-align: center;
    line-height: 40px;
    background: rgba(239, 239, 239, 1);
    color: rgba(153, 153, 153, 1);
    font-weight: 600;
    cursor: pointer;
  }
}
.searchDiolag {
  /deep/.el-dialog__header {
    padding: 10px 20px;
    background: rgba(240, 240, 240, 1);
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    font-weight: bold;
    .el-dialog__title{
        font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: 10px;
      font-size:22px;
    }
  }
  .ProductListBox{
       min-height: 96px;
    .NoProductList{
            color: rgba(102, 102, 102, 1);
            font-size: 20px;
            line-height: 96px;
            text-align: center;
    }
  ::-webkit-scrollbar {
    width: 2px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
    .HasProductList{
         width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        box-sizing: border-box;
        flex-shrink: 0;
        margin-top:10px;
    }
    .HasProductBox{
      display: flex;
        align-items: center;
        position: relative;
          width: 100%;
          background:rgba(255, 100, 16, 0.08);
         margin-bottom:10px;
          .imgBox {
              float: left;
              width: 72px;
              height: 72px;
              margin: 8px 8px;
              border: 1px solid #f6f6f6;
              position: relative;
               .soldOut{
                display: block;
                width: 70px;
                height: 70px;
                z-index: 2;
                position:absolute;
                top:2px;
                left:0px;
              }
              img {
                display: inline-block;
                width: 70px;
                height: 70px;
                overflow: hidden;
              }
            }

          .desBox {
            float: left;
            padding: 10px 0;
            width: calc(100% - 74px);
            .desTit {
              width: 100%;
              height:35px;
              line-height:35px;
              span{
                font-size: 14px;
                font-weight: bold;
                color: #333;
                float: left;
                white-space:nowrap;
                text-overflow:ellipsis;
                overflow:hidden;
                width: 140px;
                display: inline-block;
              }
            }
            .desMsg {
              width: 100%;
              height: 35px;
              line-height: 35px;
              font-size: 13px;
              color: #333;
            }
          }
          
          .SpecBox{
            line-height: 70px;
            font-size: 13px;
            color: #333333;
          }
          .PriceBox{
             line-height: 70px;
            font-size:16px;
            color: rgba(51, 51, 51, 1);
            font-weight: 600;
          }
          .PurchasePlanBtn{
            span {
              background: rgba(255, 51, 51, 1);
              color: #000;
              padding:5px 10px;
              display: inline-block;
              margin-top: 20px;
            }
          }
      }
  }
    
}
</style>
